import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Medarbetare() {
  const data = useStaticQuery(graphql`
    {
      allWpMedarbetaren(limit: 3) {
        nodes {
          title
          medarbetareInformation {
            roll
            epost
            telefon
          }
        }
      }
    }
  `)
  const posts = data.allWpMedarbetaren.nodes
  return (
    <div className="grid md:grid-cols-3 gap-16">
      {posts.map(post => {
        return <MedarbetareItem key={post.id} post={post} />
      })}
    </div>
  )
}

function MedarbetareItem({ post }) {
  const { title, medarbetareInformation } = post
  const { roll, epost, telefon } = medarbetareInformation
  return (
    <div className="flex flex-col space-y-2 w-full h-full">
      <h2 className="text-2xl font-bold">{title}</h2>
      <div className="bg-gray-200 aspect-w-3 aspect-h-4"></div>
      {/* <h3 className="font-medium">{roll}</h3> */}
      <div>
        <p>{epost}</p>
        <p>
          <a href={`tel:${telefon}`}>{telefon}</a>
        </p>
      </div>
    </div>
  )
}
